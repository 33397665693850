@import '../../styles/variables';

.navbar {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: Transparent;
  opacity: 0.9;
  color: $white;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  display: flex;
  align-items: center;
  padding: $padding-base;
  justify-content: space-between;;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;

    li {
      cursor: pointer;
      margin-right: $padding-base;

      a:hover, a.active{ 
        color: $white;
        border-bottom: 1px solid $white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.scroll-down {
    color: $white;
    position: fixed;
    background-color: $primary;
    animation: slide-in 0.7s ease-out;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.5);

    ul {
      background-color: $primary;
    }

    &.active, li:hover {
      color: $black;
    }
    
  }

  &.scroll-up { 
    display: none;
  }
}

.brand { 
  display: flex;
  align-items: stretch;
  color: $white;
  font-size: 3rem;

  svg {
    animation: flicker 5s infinite alternate;     
  }

  :first-child { 
    align-self: middle;
    margin-right: $padding-small;
  }
}

.toggle {
  margin-left: auto;
  display: none;
  transform-origin: 50% 40%;
  transition: transform 0.5s ease-out;
  cursor: pointer;

  &:hover {    
    color: $white;  
  }

  &.active { 
    transform: rotate(90deg);
    color: $white;  
  }
}

.skiplink { 
  position: absolute;
  transition: transform ease-in 350ms;
  border-bottom-left-radius: $border-radius-base; 
  border-bottom-right-radius: $border-radius-base; 
  padding: $padding-base;
  color: $secondary;
  background: rgba(0,0,0,1);
  transform: translateY(-120%);
  top: 0;
  outline: 0;

  &:focus { 
    transform: translateY(0);
  }

  &:hover { 
    color: $white;
  }
}


@keyframes slide-in {
  from { top: -60px;}
  to {top: 0px;}
}

@media #{$phones} {
  .navbar ul {
    flex-direction: column;
    position: absolute;
    left: 0;
    top: -100rem;
    width: 100%;
    padding: $padding-base;
    transition: top 1s ease-out;
    z-index: -1;

    li {
      margin-bottom: $padding-small;
      margin-left: auto;
      margin-right: 0;
    }

    &.open {
      top: 5.2rem;
    }
  }

  .toggle { 
    display: block;
    font-size: 3rem;
  }

}

@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    filter: drop-shadow(0px 0px 6px $white);
  }

  20%, 24% {       
    filter: none;
  }
}