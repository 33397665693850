@import '../../styles/variables';

.donate {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(#{$padding-large} / 2);

    button {
      position: relative; 
      cursor: pointer;
      background: transparent;
      border: none;

      &:hover img{ 
        &:last-child {
          animation: wobble 1s ease-in-out 1s;
        }

        &:first-child { 
          animation: slide-down 1s ease-out; 
        }
      }
    }

   img:last-child{ 
     height: calc(4rem + #{$padding-large});
     width: calc(4rem + #{$padding-large});
   }

   img:first-child{ 
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    height: 2.4rem;
    width: 2.4rem;
    visibility: hidden;
    z-index: 1;
  }
}

@media #{$phones} {
  .donate { 
    img:last-child { 
      height: calc(3rem + #{$padding-large});
      width: calc(3rem + #{$padding-large});
    }

    img:first-child {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
 }

@keyframes wobble {
  16.65% {
    transform: rotate(15deg);
  }
  33.3% {
    transform: rotate(-10deg);
  }
  49.95% {
    transform: rotate(8deg);
  }
  66.6% {
    transform: rotate(-4deg);
  }
  83.25% {
    transform: rotate(7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slide-down {
  0% {
    top: -3rem;
    visibility: visible;
  }

  100% {
    top: -1rem;
    visibility: hidden;
  }
}