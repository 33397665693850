@import '../../styles/variables';

.video { 
  width: 100%;
  max-width: 42rem;
  border-radius: $border-radius-large;
}

.about { 
  flex: 0.7;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-bottom: 0;
    align-self: flex-start;
  }
}

@media only screen and (max-width: 1000px) {
  .about {
    width: 100%;
    flex: initial;
  }
}

@media #{$phones} {
  .about h2 { 
    align-self: center;
  }
}