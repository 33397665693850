@import '../../styles/variables';

$header-height: 50rem;

.header { 
  position: relative;
  width: 100%;
  height: $header-height;

  img { 
    position: absolute;
    height: $header-height;
    width: 100%;
    object-fit: cover;
  }

  .overlay { 
    background-color: #000;
    opacity: 0.75;
    z-index: 2;
    width: inherit;
    position: absolute;
    height: inherit;
  }
}

.title { 
  z-index: 3;
  letter-spacing: .1em; /* Adjust as needed */
  position: absolute;
  color: $grey-light;
  font-size: 3.4rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-weight: $font-weight-bold;

  div:first-child{
    margin-bottom: 1rem;
  }

  div:last-child {
    color: $grey-light
  }
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  animation: 
    typing 3.5s steps(50, end) 0s infinite alternate,
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0% }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $grey-light }
}

@media #{$tablets} {
 .header {
   height: 40rem;

   img { 
      object-position: -50px;
   }
 }
}
