@import '../../styles/variables';

.experiences {
  &-container{
    display: grid;
    grid-template-columns: 4fr 2fr 4fr;
    grid-gap: 0 2rem;
    width: 100%;
  }

  &-title { 
    text-align: center;
    width:100%;
    margin-top: 0;
  }    
}

.experience {
  &-timeline{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: var(--row);
    grid-row-end: calc(var(--row) + 1);

    &.show { 
      .experience-line {
        height: 100%;
      }
      
      .experience-period { 
        opacity: 1;
      }
    }

    &:hover { 
      .experience-line { 
        background-color: $primary;
      }

      & + .experience-card {
        box-shadow: 0px 0px 1px 2px $primary;
      }
    }
  }

  &-dot { 
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: $primary;

    &.present { 
      background-color: $white;
      border: 2px solid $primary;
    }
  }

  &-line { 
    height: 0%;
    width: 3px;
    background-color: $grey-light;
    margin: 0.3rem 0;
    transition: height 500ms linear;
  }

  &-card { 
    background-color: $grey-light;
    border-radius: $border-radius-base;
    margin-bottom: 2rem;
    padding: $padding-base;
    grid-column-start: var(--column);
    grid-column-end: calc(var(--column) + 1);
    grid-row-start: var(--row);
    grid-row-end: calc(var(--row) + 1);
    opacity: 0;
    transition: opacity 300ms ease-in-out 200ms;

    &.show { 
      opacity: 1;
    }
  }

  &-period { 
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     font-size: 1.4rem;
     color: $grey;
     background-color: $white;
     padding: 1px 0;
     text-align: center;
     opacity: 0;
     transition: opacity 300ms ease-in-out 200ms;
  }

  &-company {
    margin: 0.2rem 0 1rem 0;
    color: $secondary;
  }

  &-position { 
    color: $primary;
    font-size: $font-size-large;
    margin: 0;
  }

  &-tasks { 
    margin: 0;
    font-size: 1.6rem;
    padding-left: $padding-large;

    li { 
      line-height: 1.8;
      font-weight: 300;
    }
  }

  &-skills { 
    padding: 0;
    list-style: none;
    display: none;
    margin: 0;
    flex-wrap: wrap;

    li { 
      margin-top: $padding-small;
    }
  }
}

@media #{$phones} {
  .experience-tasks {
    display: none;
  }

  .experience-skills {
    display: flex;
  }

  .experience-company { 
    font-size: 1.4rem;
    font-weight: 400;
  }
}

@media #{$tablets} {
  .experiences-container{
    grid-template-columns: 1fr 9fr;
    grid-gap: 0 4rem;
  }

  .experience-timeline {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .experience-card { 
    grid-column-start: 2;
    grid-column-end: 3;
  }
}