* { box-sizing: border-box }

:root {
  font-size: 10px;
  scroll-behavior: smooth;
}

body { 
  font-family: 'Lato', sans-serif;
}

.container {
  width: 100%;
}

b { 
  font-weight: bold;
}

section { 
  position: relative;
  padding: calc(2 * #{$padding-large});
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  &.grey {
    background-color: $grey-light;
  } 

  &.blue { 
    background-color: $primary;
  }
}

h2 {
  font-size: $header-font-size;
  color: $primary;
  font-weight: $font-weight-bold;
}

h3 { 
  font-size: 1.6rem;
  color: $secondary;
}

p { 
  font-weight: $font-weight-light;
  font-size: $font-size-base;
  line-height: 1.8;
}

.divider { 
  border-bottom: 2px solid $primary;
  width: 30%;

  &-vertical {
    height: 100%;
    border-right: 2px solid $primary;
  }
}

a {
   text-decoration: none;
}

.hidden { 
  visibility: hidden;
}

.pill { 
  padding: 0.8rem 1.4rem;
  color: $white;
  background-color: rgba($black,0.9);
  border-radius: 2rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: $font-weight-bold;
}

label {
  font-weight: 300;
  font-size: 1.6rem;
}

input, textarea{ 
  width: 100%;
  outline: none;
  margin-top: 0.5rem;
  border-radius: $border-radius-base;
  font-size: $font-size-large;
  border: 1px solid rgba($grey,0.3);
  padding: $padding-small;
  color: $black;
  font-weight: 300;

  &:focus { 
    border: 1px solid transparent;
    box-shadow: $primary 0px 0px 4px 0px;
  }

  + p {
    margin-bottom: 2rem;
    font-size: $font-size-small;
    margin-top: 0;
    color: $error;
  }
}

form {
  margin-top: $padding-base;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 50rem;

  button {
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 2.5rem;
    background-color: $primary;
    color: $white;
    padding: $padding-small calc(2 * #{$padding-large});
    font-size: $font-size-base;
    margin-left: auto;
    
    &:hover, &:disabled {
      background-color: $primary-light;
    }

    &:disabled { 
      cursor: not-allowed;
    }
  }
}

@media #{$phones} {
  :root {
    font-size: 8px;
  }
  
  form button { 
    margin-left: initial;
  }
}

@media only screen and (min-width: 2000px) {
  section { 
    padding-left: 20vw;
    padding-right: 20vw; 
  }
}