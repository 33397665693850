@import '../../styles/variables';

.footer { 
  border-top: 1px solid $grey-light;
}

.copyright {
  font-size: 1.4rem;
  color: $grey;
  font-weight: 300;
  text-align: center;
}