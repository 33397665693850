@import '../../styles/variables';

.social-links {
    display: flex;
    justify-content: center;
    padding-top: $padding-large;
    padding-bottom: $padding-large;

  a { 
    display: inline-block;
    font-size: 4rem;
    color: $white;
    padding: $padding-small $padding-large;

    &:hover, &:focus {
      animation: wobble-vertical 1s ease-in-out;
    }
  }
}

@media #{$phones} {
  .social-links a {
    font-size: 3rem;
  }
 }

@keyframes wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}