@import '../../styles/variables';

.contact {
  &-title {
    text-align: center;
    width: 100%;

    h2 { 
      margin-bottom: 0.4rem;
    }

    h3 { 
      margin-top: 0;
    }
  }
}

.ohnohoney{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}