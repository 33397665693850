@import '../../styles/variables';

.scroll-to-top {
  z-index: 1;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: $primary;
  position: fixed;
  bottom: $padding-large;
  right: $padding-large;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 3.5rem;
  cursor: pointer;
  box-shadow: 0px 0px 6px -1px $grey;

  &:hover { 
    background-color: $primary-light;
  }
}