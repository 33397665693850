/***  COLORS ***/
$white: #fff;
$black: #171717;
$grey: #444444;
$grey-light: #f0f0f0;
$primary: #09122C;
$primary-light: #2D365F;
$secondary: #872341;
$error: #ff0033;

/*** SPACES ***/
$padding-small: 1rem;
$padding-base: 1.5rem;
$padding-large: 2rem;

/*** BORDER RADIUS ***/
$border-radius-base: 0.6rem;
$border-radius-large: 1.2rem;

/*** FONT WEIGHT ***/
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-bold: 700;

/*** FONT SIZE ***/
$font-size-small: 1.4rem;
$font-size-base: 1.8rem;
$font-size-large: 2rem;
$header-font-size: 2.2rem;

/*** BREAKPOINTS ***/
$phones: "only screen and (max-width: 480px)";
$tablets: "only screen and (max-width: 768px)";

/*** NAVBAR ***/
$navbar-height: 4rem;